import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { AllStatisticsComponent } from './examples/allstatistics/allstatistics.component';
import { PacienteComponent } from './examples/paciente/paciente.component';
import { HistoryComponent } from './examples/history/history.component';
import { NewsletterComponent } from './examples/newsletter/newsletter.component';
import { GalleryComponent } from './examples/gallery/gallery.component';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: '*', redirectTo: 'home', pathMatch: 'full'},
    { path: 'home',             component: ComponentsComponent },
    { path: 'paciente',          component: PacienteComponent },
    { path: 'history',          component: HistoryComponent },
    { path: 'newsletter',        component: NewsletterComponent },
    { path: 'gallery/:menu',      component: GalleryComponent },
    { path: 'allstatistics',      component: AllStatisticsComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      useHash: true
    })
  ],
  exports: [ RouterModule ],
})
export class AppRoutingModule { }
