
export const fotoQendra = [
    {
        small: 'assets/img/gallery/foto_qendra/Mary-Potter-Palliative-Care-Center-1.jpg',
        medium: 'assets/img/gallery/foto_qendra/Mary-Potter-Palliative-Care-Center-1.jpg',
        big: 'assets/img/gallery/foto_qendra/Mary-Potter-Palliative-Care-Center-1.jpg'
    },
    {
        small: 'assets/img/gallery/foto_qendra/Mary-Potter-Palliative-Care-Center-2.jpg',
        medium: 'assets/img/gallery/foto_qendra/Mary-Potter-Palliative-Care-Center-2.jpg',
        big: 'assets/img/gallery/foto_qendra/Mary-Potter-Palliative-Care-Center-2.jpg'
    },
    {
        small: 'assets/img/gallery/foto_qendra/Mary-Potter-Palliative-Care-Center-3.jpg',
        medium: 'assets/img/gallery/foto_qendra/Mary-Potter-Palliative-Care-Center-3.jpg',
        big: 'assets/img/gallery/foto_qendra/Mary-Potter-Palliative-Care-Center-3.jpg'
    },
    {
        small: 'assets/img/gallery/foto_qendra/Mary-Potter-Palliative-Care-Center-4.jpg',
        medium: 'assets/img/gallery/foto_qendra/Mary-Potter-Palliative-Care-Center-4.jpg',
        big: 'assets/img/gallery/foto_qendra/Mary-Potter-Palliative-Care-Center-4.jpg'
    },
    {
        small: 'assets/img/gallery/foto_qendra/Mary-Potter-Palliative-Care-Center-5.jpg',
        medium: 'assets/img/gallery/foto_qendra/Mary-Potter-Palliative-Care-Center-5.jpg',
        big: 'assets/img/gallery/foto_qendra/Mary-Potter-Palliative-Care-Center-5.jpg'
    },
    {
        small: 'assets/img/gallery/foto_qendra/Mary-Potter-Palliative-Care-Center-6.jpg',
        medium: 'assets/img/gallery/foto_qendra/Mary-Potter-Palliative-Care-Center-6.jpg',
        big: 'assets/img/gallery/foto_qendra/Mary-Potter-Palliative-Care-Center-6.jpg'
    }
];
