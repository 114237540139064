
export const trajnime = [
    {
        small: 'assets/img/gallery/trajnime/trajnime01.jpg',
        medium: 'assets/img/gallery/trajnime/trajnime01.jpg',
        big: 'assets/img/gallery/trajnime/trajnime01.jpg'
    },
    {
        small: 'assets/img/gallery/trajnime/trajnime02.jpg',
        medium: 'assets/img/gallery/trajnime/trajnime02.jpg',
        big: 'assets/img/gallery/trajnime/trajnime02.jpg'
    },
    {
        small: 'assets/img/gallery/trajnime/trajnime03.jpg',
        medium: 'assets/img/gallery/trajnime/trajnime03.jpg',
        big: 'assets/img/gallery/trajnime/trajnime03.jpg'
    },
    {
        small: 'assets/img/gallery/trajnime/trajnime04.jpg',
        medium: 'assets/img/gallery/trajnime/trajnime04.jpg',
        big: 'assets/img/gallery/trajnime/trajnime04.jpg'
    },
    {
        small: 'assets/img/gallery/trajnime/trajnime05.jpg',
        medium: 'assets/img/gallery/trajnime/trajnime05.jpg',
        big: 'assets/img/gallery/trajnime/trajnime05.jpg'
    },
    {
        small: 'assets/img/gallery/trajnime/trajnime06.jpg',
        medium: 'assets/img/gallery/trajnime/trajnime06.jpg',
        big: 'assets/img/gallery/trajnime/trajnime06.jpg'
    },
    {
        small: 'assets/img/gallery/trajnime/trajnime07.jpg',
        medium: 'assets/img/gallery/trajnime/trajnime07.jpg',
        big: 'assets/img/gallery/trajnime/trajnime07.jpg'
    },
    {
        small: 'assets/img/gallery/trajnime/trajnime08.jpg',
        medium: 'assets/img/gallery/trajnime/trajnime08.jpg',
        big: 'assets/img/gallery/trajnime/trajnime08.jpg'
    },
    {
        small: 'assets/img/gallery/trajnime/trajnime09.jpg',
        medium: 'assets/img/gallery/trajnime/trajnime09.jpg',
        big: 'assets/img/gallery/trajnime/trajnime09.jpg'
    },
    {
        small: 'assets/img/gallery/trajnime/trajnime10.jpg',
        medium: 'assets/img/gallery/trajnime/trajnime10.jpg',
        big: 'assets/img/gallery/trajnime/trajnime10.jpg'
    },
    {
        small: 'assets/img/gallery/trajnime/trajnime11.jpg',
        medium: 'assets/img/gallery/trajnime/trajnime11.jpg',
        big: 'assets/img/gallery/trajnime/trajnime11.jpg'
    },
    {
        small: 'assets/img/gallery/trajnime/trajnime12.jpg',
        medium: 'assets/img/gallery/trajnime/trajnime12.jpg',
        big: 'assets/img/gallery/trajnime/trajnime12.jpg'
    },
    {
        small: 'assets/img/gallery/trajnime/trajnime13.jpg',
        medium: 'assets/img/gallery/trajnime/trajnime13.jpg',
        big: 'assets/img/gallery/trajnime/trajnime13.jpg'
    },
    {
        small: 'assets/img/gallery/trajnime/trajnime14.jpg',
        medium: 'assets/img/gallery/trajnime/trajnime14.jpg',
        big: 'assets/img/gallery/trajnime/trajnime14.jpg'
    },
    {
        small: 'assets/img/gallery/trajnime/trajnime15.jpg',
        medium: 'assets/img/gallery/trajnime/trajnime15.jpg',
        big: 'assets/img/gallery/trajnime/trajnime15.jpg'
    }
];
