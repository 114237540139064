
export const stafi = [
    {
        small: 'assets/img/gallery/stafi/Stafi-Mary-Potter-1.jpg',
        medium: 'assets/img/gallery/stafi/Stafi-Mary-Potter-1.jpg',
        big: 'assets/img/gallery/stafi/Stafi-Mary-Potter-1.jpg'
    },    
    {
        small: 'assets/img/gallery/stafi/Stafi-Mary-Potter-2.jpg',
        medium: 'assets/img/gallery/stafi/Stafi-Mary-Potter-2.jpg',
        big: 'assets/img/gallery/stafi/Stafi-Mary-Potter-2.jpg'
    },
    {
        small: 'assets/img/gallery/stafi/Stafi-Mary-Potter-3.jpg',
        medium: 'assets/img/gallery/stafi/Stafi-Mary-Potter-3.jpg',
        big: 'assets/img/gallery/stafi/Stafi-Mary-Potter-3.jpg'
    }
];
