import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import * as Chart from 'chart.js';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {

  canvas: any;
  ctx: any;
  ngOnInit() {

    this.canvas = document.getElementById('myChart');
    this.ctx = this.canvas.getContext('2d');
     const myChart = new Chart(this.ctx, {
      type: 'bar',
      data: {
          labels: [
            '2011',
            '2012',
            '2013',
            '2014',
            '2015',
            '2016',
            '2017',
            '2018',
            '2019',
            '2020'
          ],
          datasets: [{
            label: 'Meshkuj',
            data: [117, 139, 134, 136, 195, 168, 153, 182, 168, 121],
              backgroundColor: [
                '#ffb5b5',
                '#ffb5b5',
                '#ffb5b5',
                '#ffb5b5',
                '#ffb5b5',
                '#ffb5b5',
                '#ffb5b5',
                '#ffb5b5',
                '#ffb5b5',
                '#ffb5b5'
              ],
            borderWidth: 1
          },
          {
            label: 'Femra',
            data: [92, 96, 86, 133, 133, 94, 89, 108, 141, 136],
              backgroundColor: [
                '#8ad5d5',
                '#8ad5d5',
                '#8ad5d5',
                '#8ad5d5',
                '#8ad5d5',
                '#8ad5d5',
                '#8ad5d5',
                '#8ad5d5',
                '#8ad5d5',
                '#8ad5d5'
              ],
            borderWidth: 1
          }]
      },
      options: {
        responsive: true,
        scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true
            }
        }]
    }
      }
    });
  }
}
