import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxGalleryModule } from 'ngx-gallery';
import { RouterModule } from '@angular/router';

import { AllStatisticsComponent } from './allstatistics/allstatistics.component';
import { HistoryComponent } from './history/history.component';
import { PacienteComponent } from './paciente/paciente.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { GalleryComponent } from './gallery/gallery.component';
import { FrameComponent } from './gallery/frame/frame.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        RouterModule,
        PdfViewerModule,
        NgxGalleryModule
    ],
    declarations: [
        PacienteComponent,
        HistoryComponent,
        NewsletterComponent,
        AllStatisticsComponent,
        GalleryComponent,
        FrameComponent
    ]
})
export class ExamplesModule { }
