import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-donators',
  templateUrl: './donators.component.html',
  styleUrls: ['./donators.component.scss']
})
export class DonatorsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
