
export const hospice = [
    {
        small: 'assets/img/gallery/hospice/hospice1.jpg',
        medium: 'assets/img/gallery/hospice/hospice1.jpg',
        big: 'assets/img/gallery/hospice/hospice1.jpg'
    },
    {
        small: 'assets/img/gallery/hospice/hospice2.jpg',
        medium: 'assets/img/gallery/hospice/hospice2.jpg',
        big: 'assets/img/gallery/hospice/hospice2.jpg'
    },
    {
        small: 'assets/img/gallery/hospice/hospice3.jpg',
        medium: 'assets/img/gallery/hospice/hospice3.jpg',
        big: 'assets/img/gallery/hospice/hospice3.jpg'
    },
    {
        small: 'assets/img/gallery/hospice/hospice4.jpg',
        medium: 'assets/img/gallery/hospice/hospice4.jpg',
        big: 'assets/img/gallery/hospice/hospice4.jpg'
    },
    {
        small: 'assets/img/gallery/hospice/hospice5.jpg',
        medium: 'assets/img/gallery/hospice/hospice5.jpg',
        big: 'assets/img/gallery/hospice/hospice5.jpg'
    },
    {
        small: 'assets/img/gallery/hospice/hospice6.jpg',
        medium: 'assets/img/gallery/hospice/hospice6.jpg',
        big: 'assets/img/gallery/hospice/hospice6.jpg'
    },
    {
        small: 'assets/img/gallery/hospice/hospice7.jpg',
        medium: 'assets/img/gallery/hospice/hospice7.jpg',
        big: 'assets/img/gallery/hospice/hospice7.jpg'
    },
    {
        small: 'assets/img/gallery/hospice/hospice8.jpg',
        medium: 'assets/img/gallery/hospice/hospice8.jpg',
        big: 'assets/img/gallery/hospice/hospice8.jpg'
    },
    {
        small: 'assets/img/gallery/hospice/hospice9.jpg',
        medium: 'assets/img/gallery/hospice/hospice9.jpg',
        big: 'assets/img/gallery/hospice/hospice9.jpg'
    },
    {
        small: 'assets/img/gallery/hospice/hospice10.jpg',
        medium: 'assets/img/gallery/hospice/hospice10.jpg',
        big: 'assets/img/gallery/hospice/hospice10.jpg'
    },
    {
        small: 'assets/img/gallery/hospice/hospice11.jpg',
        medium: 'assets/img/gallery/hospice/hospice11.jpg',
        big: 'assets/img/gallery/hospice/hospice11.jpg'
    },
    {
        small: 'assets/img/gallery/hospice/hospice12.jpg',
        medium: 'assets/img/gallery/hospice/hospice12.jpg',
        big: 'assets/img/gallery/hospice/hospice12.jpg'
    },
    {
        small: 'assets/img/gallery/hospice/hospice13.jpg',
        medium: 'assets/img/gallery/hospice/hospice13.jpg',
        big: 'assets/img/gallery/hospice/hospice13.jpg'
    },
    {
        small: 'assets/img/gallery/hospice/hospice14.jpg',
        medium: 'assets/img/gallery/hospice/hospice14.jpg',
        big: 'assets/img/gallery/hospice/hospice14.jpg'
    },
    {
        small: 'assets/img/gallery/hospice/hospice15.jpg',
        medium: 'assets/img/gallery/hospice/hospice15.jpg',
        big: 'assets/img/gallery/hospice/hospice15.jpg'
    },
    {
        small: 'assets/img/gallery/hospice/hospice16.jpg',
        medium: 'assets/img/gallery/hospice/hospice16.jpg',
        big: 'assets/img/gallery/hospice/hospice16.jpg'
    },
    {
        small: 'assets/img/gallery/hospice/hospice17.jpg',
        medium: 'assets/img/gallery/hospice/hospice17.jpg',
        big: 'assets/img/gallery/hospice/hospice17.jpg'
    },
    {
        small: 'assets/img/gallery/hospice/hospice18.jpg',
        medium: 'assets/img/gallery/hospice/hospice18.jpg',
        big: 'assets/img/gallery/hospice/hospice18.jpg'
    },
    {
        small: 'assets/img/gallery/hospice/hospice19.jpg',
        medium: 'assets/img/gallery/hospice/hospice19.jpg',
        big: 'assets/img/gallery/hospice/hospice19.jpg'
    },
    {
        small: 'assets/img/gallery/hospice/hospice20.jpg',
        medium: 'assets/img/gallery/hospice/hospice20.jpg',
        big: 'assets/img/gallery/hospice/hospice20.jpg'
    },
    {
        small: 'assets/img/gallery/hospice/hospice21.jpg',
        medium: 'assets/img/gallery/hospice/hospice21.jpg',
        big: 'assets/img/gallery/hospice/hospice21.jpg'
    }
];
