import { Component, OnInit, HostListener } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  scrWidth: any;
  collapse = true;
  gallery = 'dayCare';

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
    if (this.scrWidth > 767) {
      this.collapse = true;
    }
  }

  constructor() {
    this.getScreenSize();
  }

  menuToggle() {
    this.collapse = !this.collapse;
  };

  changeGallery(gallery: string) {
    this.gallery = gallery;
  }

  ngOnInit() {
  }

}

