import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { RouterModule } from '@angular/router';

import { StatisticsComponent } from './statistics/statistics.component';
import { PatientHistoryComponent } from './patient-history/patient-history.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { HistoryComponent } from './history/history.component';
import { DonatorsComponent } from './donators/donators.component';

import { ComponentsComponent } from './components.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ChartsModule } from 'ng2-charts';
import { HospiceSealComponent } from './about-us/hospice-seal/hospice-seal.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NouisliderModule,
        RouterModule,
        JwBootstrapSwitchNg2Module,
        PdfViewerModule,
        ChartsModule
    ],
    declarations: [
        ComponentsComponent,
        StatisticsComponent,
        PatientHistoryComponent,
        AboutUsComponent,
        HistoryComponent,
        DonatorsComponent,
        HospiceSealComponent
    ],
    exports: [
        ComponentsComponent
    ]
})
export class ComponentsModule { }
