import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-paciente',
  templateUrl: './paciente.component.html',
  styleUrls: ['./paciente.component.scss']
})
export class PacienteComponent implements OnInit {

  scrWidth: any;
  collapse = true;
  history = 'dajana';

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
    if (this.scrWidth > 767) {
      this.collapse = true;
    }
  }

  constructor() {
    this.getScreenSize();
  }

  menuToggle() {
    this.collapse = !this.collapse;
  };

  changeHistory(history: string) {
    this.history = history;
  }

  ngOnInit() {
  }

}

