import { Component, ElementRef, ViewChild, HostListener, AfterViewInit, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { Observable, of } from 'rxjs';
import * as Chart from 'chart.js';
import { BrowserStack } from 'protractor/built/driverProviders';

@Component({
  selector: 'app-allstatistics',
  templateUrl: './allstatistics.component.html',
  styleUrls: ['./allstatistics.component.scss']
})
export class AllStatisticsComponent implements OnInit {

  title: string;
  scrWidth: any;
  collapse = true;
  statistic = 'paciente_11_20';
  canvas1: any;
  ctx1: any;
  canvas2: any;
  ctx2: any;
  canvas3: any;
  ctx3: any;
  canvas4: any;
  ctx4: any;
  canvas5: any;
  ctx5: any;
  canvas6: any;
  ctx6: any;
  canvas7: any;
  ctx7: any;
  canvas8: any;
  ctx8: any;
  myChart1: Chart;
  myChart2: Chart;
  myChart3: Chart;
  myChart4: Chart;
  myChart5: Chart;
  myChart6: Chart;
  myChart7: Chart;
  myChart8: Chart;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
    if (this.scrWidth > 767) {
      this.collapse = true;
    }
  }

  constructor() {
    this.getScreenSize();
  }

  menuToggle() {
    this.collapse = !this.collapse;
  };

  chartComplete() {
    switch (this.statistic) {
      case 'paciente_11_20': {
        this.myChart1 = new Chart
          (this.ctx1, {
          type: 'bar',
          data: {
              labels: [
                '2011',
                '2012',
                '2013',
                '2014',
                '2015',
                '2016',
                '2017',
                '2018',
                '2019',
                '2020',
                '2021'
              ],
              datasets: [{
                label: 'Meshkuj',
                data: [117, 139, 134, 136, 195, 168, 153, 182, 168, 121, 166],
                  backgroundColor: [
                    '#ffb5b5',
                    '#ffb5b5',
                    '#ffb5b5',
                    '#ffb5b5',
                    '#ffb5b5',
                    '#ffb5b5',
                    '#ffb5b5',
                    '#ffb5b5',
                    '#ffb5b5',
                    '#ffb5b5',
                    '#ffb5b5'
                  ],
                borderWidth: 1
              },
              {
                label: 'Femra',
                data: [92, 96, 86, 133, 133, 94, 89, 108, 141, 136, 115],
                  backgroundColor: [
                    '#8ad5d5',
                    '#8ad5d5',
                    '#8ad5d5',
                    '#8ad5d5',
                    '#8ad5d5',
                    '#8ad5d5',
                    '#8ad5d5',
                    '#8ad5d5',
                    '#8ad5d5',
                    '#8ad5d5',
                    '#8ad5d5'
                  ],
                borderWidth: 1
              }]
          },
          options: {
            responsive: true,
            scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
          }
        });
        this.title = 'Numri i pacientëve që kanë marrë kujdes paliativ 2011-2021';
        break;
      }
      case 'gjinia': {
        this.myChart2 = new Chart (this.ctx2, {
          type: 'pie',
          data: {
              labels: [
                'Femra',
                'Meshkuj'
              ],
              datasets: [{
                  label: 'Shperndarja sipas gjinise',
                  data: [1223, 1679],
                  backgroundColor: [
                    '#fbe297',
                    '#adbaa9'
                  ],
                  borderWidth: 1
              }]
          },
          options: {
            responsive: true,
          }
        });
        this.title = 'Numri i pacientëve për periudhën 2011-2021 sipas gjinisë';
        break;
      }
      case 'diagnoza_11_20': {
        this.myChart3 = new Chart
          (this.ctx3, {
          type: 'bar',
          data: {
              labels: [
                '2011',
                '2012',
                '2013',
                '2014',
                '2015',
                '2016',
                '2017',
                '2018',
                '2019',
                '2020',
                '2021'
              ],
              datasets: [{
                label: 'Ca pulmoni',
                data: [35, 46, 34, 59, 67, 57, 42, 54, 46, 58, 49],
                  backgroundColor: [
                    '#bc99c7',
                    '#bc99c7',
                    '#bc99c7',
                    '#bc99c7',
                    '#bc99c7',
                    '#bc99c7',
                    '#bc99c7',
                    '#bc99c7',
                    '#bc99c7',
                    '#bc99c7',
                    '#bc99c7'
                  ],
                borderWidth: 1
              },
              {
                label: 'Ca ventriculi',
                data: [30, 27, 31, 27, 43, 30, 15, 15, 42, 20, 30],
                  backgroundColor: [
                    '#8ad5d5',
                    '#8ad5d5',
                    '#8ad5d5',
                    '#8ad5d5',
                    '#8ad5d5',
                    '#8ad5d5',
                    '#8ad5d5',
                    '#8ad5d5',
                    '#8ad5d5',
                    '#8ad5d5',
                    '#8ad5d5'
                  ],
                borderWidth: 1
              },
              {
                label: 'Ca mame',
                data: [14, 17, 14, 24, 28, 21, 20, 21, 26, 23, 20],
                  backgroundColor: [
                    '#8196ad',
                    '#8196ad',
                    '#8196ad',
                    '#8196ad',
                    '#8196ad',
                    '#8196ad',
                    '#8196ad',
                    '#8196ad',
                    '#8196ad',
                    '#8196ad',
                    '#8196ad'
                  ],
                borderWidth: 1
              },
              {
                label: 'Ca pankreasi',
                data: [6, 15, 18, 20, 29, 14, 15, 10, 20, 13, 21],
                  backgroundColor: [
                    '#fbe297',
                    '#fbe297',
                    '#fbe297',
                    '#fbe297',
                    '#fbe297',
                    '#fbe297',
                    '#fbe297',
                    '#fbe297',
                    '#fbe297',
                    '#fbe297',
                    '#fbe297'
                  ],
                borderWidth: 1
              },
              {
                label: 'Ca colono-rectali',
                data: [8, 20, 0, 33, 20, 20, 19, 44, 41, 32, 25],
                  backgroundColor: [
                    '#ffb5b5',
                    '#ffb5b5',
                    '#ffb5b5',
                    '#ffb5b5',
                    '#ffb5b5',
                    '#ffb5b5',
                    '#ffb5b5',
                    '#ffb5b5',
                    '#ffb5b5',
                    '#ffb5b5',
                    '#ffb5b5'
                  ],
                borderWidth: 1
              },
              {
                label: 'Ca hepari',
                data: [13, 12, 8, 11, 17, 11, 13, 31, 11, 14, 5],
                  backgroundColor: [
                    '#adbaa9',
                    '#adbaa9',
                    '#adbaa9',
                    '#adbaa9',
                    '#adbaa9',
                    '#adbaa9',
                    '#adbaa9',
                    '#adbaa9',
                    '#adbaa9',
                    '#adbaa9',
                    '#adbaa9'
                  ],
                borderWidth: 1
              }]
          },
          options: {
            responsive: true,
            scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
          }
        });
        this.title = 'Pacientët sipas diagnozës 2011-2021';
        break;
      }
      case 'vizitaShtepi': {
        this.myChart4 = new Chart (this.ctx4, {
          type: 'line',
          data: {
            labels: [
              '2011',
              '2012',
              '2013',
              '2014',
              '2015',
              '2016',
              '2017',
              '2018',
              '2019',
              '2020',
              '2021'
            ],
            datasets: [{
              label: 'Vizita ne shtepi 2011-2021',
              fill: false,
              data: [1125, 1368, 1566, 1928, 2328, 2066, 2618, 2511, 2675, 2307, 2382],
              backgroundColor: [
                '#ffb5b5',
                '#8ad5d5',
                '#8196ad',
                '#fbe297',
                '#bc99c7',
                '#817e94',
                '#b6afb0',
                '#adbaa9',
                '#a98b9c',
                '#f4d1ae',
                '#fbe297',
              ],
              borderColor: '#33BFE3',
              borderWidth: 1
            }]
          },
          options: {
            responsive: true,
            scales: {
              yAxes: [{
                display: true
              }],
              xAxes: [{
                display: true
              }]
            }
          },
        });
        this.title = 'Numri i vizitave në shtëpi 2011-2021';
        break;
      }
      case 'vizitaQender': {
        this.myChart5 = new Chart (this.ctx5, {
          type: 'pie',
          data: {
            labels: [
              '2011',
              '2012',
              '2013',
              '2014',
              '2015',
              '2016',
              '2017',
              '2018',
              '2019',
              '2020',
              '2021'
            ],
            datasets: [{
              label: 'Shperndarja sipas vendbanimit',
              data: [4210, 3641, 2771, 2516, 2151, 2349, 1740, 2066, 1740, 1132, 913],
              backgroundColor: [
                '#ffb5b5',
                '#8ad5d5',
                '#8196ad',
                '#fbe297',
                '#bc99c7',
                '#817e94',
                '#b6afb0',
                '#adbaa9',
                '#a98b9c',
                '#f4d1ae',
                '#B9E69E'
              ],
              borderWidth: 1
            }]
          },
          options: {
            responsive: true,
          }
        });
        this.title = 'Numri i vizitave në Qendër 2011-2021';
        break;
      }
      case 'kimioterapi': {
        this.myChart6 = new Chart (this.ctx6, {
          type: 'bar',
          data: {
            labels: [
              '2011',
              '2012',
              '2013',
              '2014',
              '2015',
              '2016',
              '2017',
              '2018',
              '2019',
              '2020',
              '2021'
            ],
            datasets: [{
              label: 'Cikle Kimioterapie',
              data: [406, 530, 430, 346, 170, 217, 52, 66, 114, 69, 160],
                backgroundColor: [
                  '#8ad5d5',
                  '#8ad5d5',
                  '#8ad5d5',
                  '#8ad5d5',
                  '#8ad5d5',
                  '#8ad5d5',
                  '#8ad5d5',
                  '#8ad5d5',
                  '#8ad5d5',
                  '#8ad5d5',
                  '#8ad5d5'
                ],
              borderWidth: 1
            }]
          },
          options: {
            responsive: true,
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true
                }
              }]
            }
          }
        });
        this.title = 'Cikle kimioterapie 2011-2021';
        break;
      }
      case 'grupmosha': {
        this.myChart7 = new Chart (this.ctx7, {
          type: 'pie',
          data: {
            labels: [
              '<30',
              '31-40',
              '41-50',
              '51-60',
              '61-70',
              '71-80',
              '>80',
            ],
            datasets: [{
              label: 'Ne %',
              data: [1, 2, 7, 18, 28, 32, 12],
              backgroundColor: [
                '#ffb5b5',
                '#fbe297',
                '#8196ad',
                '#bc99c7',
                '#817e94',
                '#8ad5d5',
                '#b6afb0'
              ],
              borderWidth: 1
            }]
          },
          options: {
            responsive: true,
          }
        });
        this.title = 'Pacientët sipas grupmoshave 2011-2021 (%)';
        break;
      }
      case 'personeli': {
        this.myChart8 = new Chart (this.ctx8, {
          type: 'pie',
          data: {
            labels: [
              'Mjekë',
              'Infermierë',
              'Profesionistë të tjerë të shëndetit'
            ],
            datasets: [{
              label: 'Personeli i trajnuar',
              data: [1913, 6433, 643],
              backgroundColor: [
                '#fbe297',
                '#b6afb0',
                '#817e94'
              ],
              borderWidth: 1
            }]
          },
          options: {
            responsive: true,
          }
        });
        this.title = 'Personeli i trajnuar nga Qendra "Mary Potter" 2011-2021';
        break;
      }
      default: {
        break;
      }
    }
  }

  changeStatistic(statistic: string) {
    this.statistic = statistic;
    this.chartComplete();
  }

  ngOnInit() {
    this.canvas1 = document.getElementById('myChart1');
    this.ctx1 = this.canvas1.getContext('2d');
    this.canvas2 = document.getElementById('myChart2');
    this.ctx2 = this.canvas2.getContext('2d');
    this.canvas3 = document.getElementById('myChart3');
    this.ctx3 = this.canvas3.getContext('2d');
    this.canvas4 = document.getElementById('myChart4');
    this.ctx4 = this.canvas4.getContext('2d');
    this.canvas5 = document.getElementById('myChart5');
    this.ctx5 = this.canvas5.getContext('2d');
    this.canvas6 = document.getElementById('myChart6');
    this.ctx6 = this.canvas6.getContext('2d');
    this.canvas7 = document.getElementById('myChart7');
    this.ctx7 = this.canvas7.getContext('2d');
    this.canvas8 = document.getElementById('myChart8');
    this.ctx8 = this.canvas8.getContext('2d');
    this.chartComplete();
  }
}
