import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hospice-seal',
  templateUrl: './hospice-seal.component.html',
  styleUrls: ['./hospice-seal.component.scss']
})
export class HospiceSealComponent implements OnInit {

  @Input() sealId: string;
  @Input() imgWidth: number = 75;

  linkUrl: string;
  imageUrl: string;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.linkUrl = `https://hospicecare.com/?utm_campaign=seal`;
    this.imageUrl = `https://hospicecare.com/seal/${this.sealId}.png?requestUrl=${window.location.href}`;

    const anchorElement = this.elementRef.nativeElement.querySelector('a');
    anchorElement.setAttribute('href', this.linkUrl);

    const imgElement = this.elementRef.nativeElement.querySelector('img');
    imgElement.setAttribute('src', this.imageUrl);
    imgElement.setAttribute('width', this.imgWidth.toString());
  }
}