import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';

import { dayCare } from '../images_classes/dayCare';
import { fotoQendra } from '../images_classes/fotoQendra';
import { homecare } from '../images_classes/homeCare';
import { hospice } from '../images_classes/hospice';
import { stafi } from '../images_classes/stafi';
import { trajnime } from '../images_classes/trajnime';

@Component({
  selector: 'app-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss']
})
export class FrameComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

    constructor(private route: ActivatedRoute, private router: Router) {
        router.events.subscribe(() => {
            const menu = this.route.snapshot.paramMap.get('menu');
            this.switchGalleryMenu(menu);
        });
    }

  ngOnInit(): void {

      this.galleryOptions = [
          {
              width: '100%',
              height: '600px',
              thumbnailsColumns: 4,
              imageAnimation: NgxGalleryAnimation.Slide
          },
          // max-width 800
          {
              breakpoint: 800,
              width: '100%',
              height: '600px',
              imagePercent: 80,
              thumbnailsPercent: 20,
              thumbnailsMargin: 20,
              thumbnailMargin: 20
          },
          // max-width 400
          {
              breakpoint: 400,
              preview: false
          }
      ];

      const menu = this.route.snapshot.paramMap.get('menu');
      this.switchGalleryMenu(menu);
  }

  switchGalleryMenu(menu: string) {
    switch (menu) {
        case 'dayCare': {
            this.galleryImages = dayCare;
            break;
        }
        case 'qendra': {
            this.galleryImages = fotoQendra;
            break;
        }
        case 'homeCare': {
            this.galleryImages = homecare;
            break;
        }
        case 'hospice': {
            this.galleryImages = hospice;
            break;
        }
        case 'trajnime': {
            this.galleryImages = trajnime;
            break;
        }
        case 'stafi': {
            this.galleryImages = stafi;
            break;
        }
        default: {
            this.galleryImages = dayCare;
            break;
        }
    }
  }

}
