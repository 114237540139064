
export const dayCare = [
    {
        small: 'assets/img/gallery/day_care/Day-Care-1.jpg',
        medium: 'assets/img/gallery/day_care/Day-Care-1.jpg',
        big: 'assets/img/gallery/day_care/Day-Care-1.jpg'
    },
    {
        small: 'assets/img/gallery/day_care/Day-Care-2.jpg',
        medium: 'assets/img/gallery/day_care/Day-Care-2.jpg',
        big: 'assets/img/gallery/day_care/Day-Care-2.jpg'
    },
    {
        small: 'assets/img/gallery/day_care/Day-Care-3.jpg',
        medium: 'assets/img/gallery/day_care/Day-Care-3.jpg',
        big: 'assets/img/gallery/day_care/Day-Care-3.jpg'
    },
    {
        small: 'assets/img/gallery/day_care/Day-Care-4.jpg',
        medium: 'assets/img/gallery/day_care/Day-Care-4.jpg',
        big: 'assets/img/gallery/day_care/Day-Care-4.jpg'
    },
    {
        small: 'assets/img/gallery/day_care/Day-Care-5.jpg',
        medium: 'assets/img/gallery/day_care/Day-Care-5.jpg',
        big: 'assets/img/gallery/day_care/Day-Care-5.jpg'
    },
    {
        small: 'assets/img/gallery/day_care/Day-Care-6.jpg',
        medium: 'assets/img/gallery/day_care/Day-Care-6.jpg',
        big: 'assets/img/gallery/day_care/Day-Care-6.jpg'
    },
    {
        small: 'assets/img/gallery/day_care/Day-Care-7.jpg',
        medium: 'assets/img/gallery/day_care/Day-Care-7.jpg',
        big: 'assets/img/gallery/day_care/Day-Care-7.jpg'
    }
];
