
export const homecare = [
    {
        small: 'assets/img/gallery/home_care/homecare1.jpg',
        medium: 'assets/img/gallery/home_care/homecare1.jpg',
        big: 'assets/img/gallery/home_care/homecare1.jpg'
    },
    {
        small: 'assets/img/gallery/home_care/homecare2.jpg',
        medium: 'assets/img/gallery/home_care/homecare2.jpg',
        big: 'assets/img/gallery/home_care/homecare2.jpg'
    },
    {
        small: 'assets/img/gallery/home_care/homecare3.jpg',
        medium: 'assets/img/gallery/home_care/homecare3.jpg',
        big: 'assets/img/gallery/home_care/homecare3.jpg'
    },
    {
        small: 'assets/img/gallery/home_care/homecare4.jpg',
        medium: 'assets/img/gallery/home_care/homecare4.jpg',
        big: 'assets/img/gallery/home_care/homecare4.jpg'
    },
    {
        small: 'assets/img/gallery/home_care/homecare5.jpg',
        medium: 'assets/img/gallery/home_care/homecare5.jpg',
        big: 'assets/img/gallery/home_care/homecare5.jpg'
    },
    {
        small: 'assets/img/gallery/home_care/homecare6.jpg',
        medium: 'assets/img/gallery/home_care/homecare6.jpg',
        big: 'assets/img/gallery/home_care/homecare6.jpg'
    },
    {
        small: 'assets/img/gallery/home_care/homecare7.jpg',
        medium: 'assets/img/gallery/home_care/homecare7.jpg',
        big: 'assets/img/gallery/home_care/homecare7.jpg'
    },
    {
        small: 'assets/img/gallery/home_care/homecare8.jpg',
        medium: 'assets/img/gallery/home_care/homecare8.jpg',
        big: 'assets/img/gallery/home_care/homecare8.jpg'
    },
    {
        small: 'assets/img/gallery/home_care/homecare9.jpg',
        medium: 'assets/img/gallery/home_care/homecare9.jpg',
        big: 'assets/img/gallery/home_care/homecare9.jpg'
    },
    {
        small: 'assets/img/gallery/home_care/homecare10.jpg',
        medium: 'assets/img/gallery/home_care/homecare10.jpg',
        big: 'assets/img/gallery/home_care/homecare10.jpg'
    },
    {
        small: 'assets/img/gallery/home_care/homecare11.jpg',
        medium: 'assets/img/gallery/home_care/homecare11.jpg',
        big: 'assets/img/gallery/home_care/homecare11.jpg'
    },
    {
        small: 'assets/img/gallery/home_care/homecare12.jpg',
        medium: 'assets/img/gallery/home_care/homecare12.jpg',
        big: 'assets/img/gallery/home_care/homecare12.jpg'
    },
    {
        small: 'assets/img/gallery/home_care/homecare13.jpg',
        medium: 'assets/img/gallery/home_care/homecare13.jpg',
        big: 'assets/img/gallery/home_care/homecare13.jpg'
    },
    {
        small: 'assets/img/gallery/home_care/homecare14.jpg',
        medium: 'assets/img/gallery/home_care/homecare14.jpg',
        big: 'assets/img/gallery/home_care/homecare14.jpg'
    },
    {
        small: 'assets/img/gallery/home_care/homecare15.jpg',
        medium: 'assets/img/gallery/home_care/homecare15.jpg',
        big: 'assets/img/gallery/home_care/homecare15.jpg'
    },
    {
        small: 'assets/img/gallery/home_care/homecare16.jpg',
        medium: 'assets/img/gallery/home_care/homecare16.jpg',
        big: 'assets/img/gallery/home_care/homecare16.jpg'
    },
    {
        small: 'assets/img/gallery/home_care/homecare17.jpg',
        medium: 'assets/img/gallery/home_care/homecare17.jpg',
        big: 'assets/img/gallery/home_care/homecare17.jpg'
    },
    {
        small: 'assets/img/gallery/home_care/homecare18.jpg',
        medium: 'assets/img/gallery/home_care/homecare18.jpg',
        big: 'assets/img/gallery/home_care/homecare18.jpg'
    }
];
